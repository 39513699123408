import { template as template_909b9e02bfa44856bec4052a410281b7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_909b9e02bfa44856bec4052a410281b7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
