import { template as template_72f8fb88e0d048148e7fb6bf535b93d0 } from "@ember/template-compiler";
const FKText = template_72f8fb88e0d048148e7fb6bf535b93d0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
