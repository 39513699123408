import { template as template_e9aae13d19874a058b03ba80dc75bbda } from "@ember/template-compiler";
const FKLabel = template_e9aae13d19874a058b03ba80dc75bbda(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
