import { template as template_ce3d2a657ec145bb98b9ea2eca253a5c } from "@ember/template-compiler";
const WelcomeHeader = template_ce3d2a657ec145bb98b9ea2eca253a5c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
