import { template as template_85fc3d7025b8470a886f21cf86a9aa68 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_85fc3d7025b8470a886f21cf86a9aa68(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
