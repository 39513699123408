import { template as template_c191e300c605468ea3481d0f11f58a25 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_c191e300c605468ea3481d0f11f58a25(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
